<script>
import env from '../env';
import GlobalVue from '../helper/Global.vue';

export default {
  extends: GlobalVue,
  props:{src:String,lazySrc:String},
  data(){
    return {
      validImage: false,
    }
  },
  computed:{
    // page(){ return this.$root.page },
    lazyImg(){
      if(this.src) return this.src
      if(!this.lazySrc) return this.src
      if(this.lazySrc.indexOf("base64")>-1) return this.lazySrc
      if(!this.src) return this.assets("fo_images", "noimage_square.jpg");
      var pathFile = this.lazySrc.split(env.baseUrl)[1]
      if(!pathFile) return;
      if(pathFile.split("/img/")[1]){
        var x = pathFile.split("/"); x.splice(0,3); pathFile = "/"+x.join("/")
      }
      return env.baseUrl+"/img/10"+pathFile.replace("uploader/", "")
    }
  },
  mounted(){
    this.lazyInit()
  },
  methods:{
    lazyInit(){
      this.validImage = false
      if(!this.lazySrc) return;
      if(!/\.(jpg|jpeg|png|gif)$/g.test(this.lazySrc.split("?")[0])) return;
      var img = new Image;
      img.onload = ()=>{
        this.validImage = true
      }
      img.onerror = ()=>{
        this.validImage = false
      }
      img.src = this.lazySrc
    }
  },
  watch:{
    lazySrc(){ this.lazyInit() },
  }
}
</script>

<template>
  <img :src="validImage?lazySrc:lazyImg"/>
</template>
