const BoConfig = {
    sidebar: [
        {name:"Dashboard",link:"BoDashboard",icon:"fas fa-tachometer-alt"},
        {name:"Master",title:"Master",icon:"ti-layout-grid2",childs:[
            {name:"API",link:"MrApi"},
            {name:"Service",link:"MrService"},
            {name:"Client",link:"MrClient"},
        ]},
        {name:"Quota Management",link:"AppManagementQuota",icon:"icon-settings"},
        {name:"Report",link:"AppRequest",icon:"icon-doc"},
        {name:"User Management",title:"UserManagement", icon:"fas fa-users",childs:[
            {name:"Users",link:"BoUsers"},
            {name:"User Level",link:"BoUserLevel"},
        ]},
        {name:"How To",link:"BoPanduan",icon:"ti-light-bulb"},
    ],
}
export default BoConfig