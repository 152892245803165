<script>
import env from '@root/env';
import BOGen from '@helper/BOGen';
import Gen from '@helper/Gen';
import BoConfig from '../config/BoConfig';
import Config from '../config/Config';

export default {
  data() {
    return {
      AjaxReq: [],
    }
  },
  computed:{
    baseUrl(){ return env.baseUrl },
    hl(){ return this.$root.hl },
    currentUrl(){ return env.baseUrl+this.$root.pathname },
    subscribeActive(){ return this.$root.subscribeActive },
    meta(){ return this.$root.meta },
    dataYear(){ return this.$root.dataYear },
    user(){ return this.$root.user },
		userFe(){ return this.$root.userFe },
		fields(){ return this.$root.fields },
		web(){ return this.$root.web },
		app(){ return this.$root.app },
    page(){ return this.$root.page },
    BoConfig(){ return BoConfig },
    Config(){ return Config },
    loadingOverlay:{
      get(){ return this.$root.var.loadingOverlay },
      set(v){ return this.$root.var.loadingOverlay = v },
    },
    xAjax:{
      get(){ return this.AjaxReq || [] },
      set(v){ return this.AjaxReq.push(v) },
    },
    isMobile(){ return this.$root.isMobile },
    isTablet(){ return this.$root.isTablet },
  },
  methods:{
    async ready(){
      if(this.$root.ready) return true;
      return new Promise((resolve)=>{
        var i = setInterval(()=>{ if(this.$root.ready){ clearInterval(i); resolve() } }, 50)
      }) 
    },
    assets(type, filename) {
      return env.baseUrl + "/" + (this.app.path[type]||type) + (filename ? "/" + filename : "" );
    },
    uploader(filename, size) {
      if(env.s3url){
        var sizePath = size ? "/img/"+size+"/" : "/";
        return env.s3url + sizePath + (filename ? filename : "");
      }
      var sizePath = size ? "/img/"+size+"/" : "/uploader/";
      return env.baseUrl + sizePath + (filename ? filename : "");
    },
    refreshVariable(){
      var variable = Gen.getStorage("variable", {});
      Object.keys(variable).forEach((k)=>{
        this.$root[k] = variable[k]
      })
      BOGen.apirest("/variable", {}, (err, resp)=>{
        if(err) return;
        Gen.putStorage("variable", resp)
        Object.keys(resp).forEach((k)=>{
          this.$root[k] = resp[k]
        })
      })
    },
    refreshVariableFo(){
      var variable = Gen.getStorage("variable", {});
      Object.keys(variable).forEach((k)=>{
        this.$root[k] = variable[k]
      })
      Gen.apirest("/variable", {}, (err, resp)=>{
        if(err) return;
        Gen.putStorage("variable", resp)
        Object.keys(resp).forEach((k)=>{
          this.$root[k] = resp[k]
        })
        global.variableLoaded = true
      })
    },
    LoadingOverlay(params = {}){
      return this.$loading.show(params);
    },
    /* Form CRUD Function */
    validation(name){
      if(!this.mrValidation) return {}
      if(!this.mrValidation[name]) return {}
      var validation = {}
      this.mrValidation[name].split("|").forEach((v)=>{
        var x = v.split(":")
        // if(x[0]=="password") return setTimeout(()=>{ $("[name='"+name+"']").rules("add", {regexPassword:true})}, 300)
        if(x[0]=="equalTo") return setTimeout(()=>{ $("[name='"+name+"']").rules("add", {equalTo:x[1]})}, 300)
        validation[x[0]] = x[1] || true
        if(x[0]=="min") validation["minlength"] = x[1]
        if(x[0]=="max") validation["maxlength"] = x[1]
      })
      return validation
    },
    openModal(el, params = {}){
      $.magnificPopup.open({
        items: {src: el },
        type: 'inline',
        mainClass: 'mfp-no-margins mfp-fade',
        closeBtnInside: false,
        fixedContentPos: true,
        overflowY: 'scroll'
      })
    }
  },
  destroyed() {
    this.xAjax.forEach((v,k)=>{ if(typeof v.abort != 'undefined') v.abort(); })
    this.AjaxReq = []
  },
}
</script>
